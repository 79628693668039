import * as React from "react";
import { Link, graphql } from "gatsby";
import Nav from "../components/nav";
import Header from "../components/header";
import Contact from "../components/contact";
import Feature from "../components/feature";
import Logo from "../images/ABx Logo Blue.png";

/*
{features.map(({node: feature}, idx) => {
          return idx % 2 == 0 ?
              <div key={idx}>
                hello 2
                  <Feature title={feature.title} description={feature.description} img={feature.image} />
              </div> :
              (<div key={idx}>
                    Hello
                      <Feature title={feature.title} description={feature.description} img={feature.image} reverse={true} />
                  </div> )  
              })}
*/

const IndexPage = ({ location, data }) => {
  console.log(location);
  let features = data.allContentfulFeatures.edges;
  const btn1 = {
    colour: "pink",
    text: "Join the beta program",
    //link: "/joinslack",
    link: "https://join.slack.com/t/alchemyboxworkspace/shared_invite/zt-qvv43zkg-m3HU3YbjV1w_jA~uyxzAMg",
  };
  const header_button = [btn1];
  features = features.reduce(function (arr, feature) {
    arr[feature.node.order] = feature;
    return arr;
  }, []);
  console.log(features);

  return (
    <main className="bg-white">
      <title>Alchemy Box - Your CFO in the cloud</title>
      <div className="flex flex-row">
        <div className="flex flex-none">
          <Nav colour="white" default_state={location.hash.replace("#", "")} />
        </div>
        <div className="flex-grow" />
        <div className="flex sm:hidden w-20 my-2 mr-8 flex-none">
          <Link to="/">
            <img
              className="object-contain h-18 scale-90"
              src={Logo}
              alt="Alchemy Box Logo"
            />
          </Link>
        </div>
      </div>
      <Header
        title="Your CFO in the cloud"
        colour="blue"
        subtitle={
          <div>
            <p className="mt-2 mb-5">
              Intelligent software to grow your business and stop you running
              out of cash.
            </p>
            <p className="mb-3">
              We are now accepting accepting business to join our beta program,
              so sign up now to try the features as they are released for free!
            </p>
          </div>
        }
        buttons={header_button}
        margin_bottom={24}
        clouds={true}
      />
      <a id="features" />
      <div className="pt-16 sm:pt-0">
        {features.map(({ node: feature }, idx) => {
          return idx % 2 ? (
            <Feature
              title={feature.title}
              description={feature.description.description}
              img={feature.image}
              key={idx}
            />
          ) : (
            <Feature
              title={feature.title}
              description={feature.description.description}
              img={feature.image}
              reverse={true}
              key={idx}
            />
          );
        })}
      </div>
      <a id="contact" />
      <Contact />
    </main>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allContentfulFeatures {
      edges {
        node {
          title
          id
          order
          description {
            description
          }
          image {
            title
            gatsbyImageData
          }
        }
      }
    }
  }
`;
