import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Feature = (props) => {
  const { title, description, img, reverse } = props;
  let featureClass = {};
  if (reverse) {
    featureClass = {
      container: "px-0 sm:px-5 py-10 font-poppins bg-white pb-16",
      main: "flex flex-row-reverse justify-center md:flex-nowrap flex-wrap-reverse align-middle text-center md:text-justify lg:mx-24",
    };
  } else {
    featureClass = {
      container: "px-0 sm:px-5 py-10 font-poppins bg-abx-light-grey pb-16",
      main: "flex flex-row justify-center md:flex-nowrap flex-wrap-reverse align-middle text-center md:text-justify lg:mx-24",
    };
  }
  return (
    <div className={featureClass.container}>
      <div className="flex flex-col justify-center mx-2 md:mx-10">
        <div className="font-bold text-abx-dark-blue text-3xl lg:text-4xl my-10 w-full text-center">
          <h1>{title}</h1>
        </div>
        <div className={featureClass.main}>
          <div className="w-full mt-5 sm:mt-0 mx-6 sm:mx-10 text-md">
            {description}
          </div>
          <div className="object-contain">
            <GatsbyImage
              image={img.gatsbyImageData}
              className="object-contain md:w-full w-full md:max-w-2xl max-w-sm"
              alt={img.title}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
