import { Link } from "gatsby";
import React from "react";

const Contact = () => {
  return (
    <div className="bg-abx-light-grey flex justify-center items-center m-30">
      <div className="flex items-center justify-center pt-30 mb-20 w-full font-poppins py-10 sm:px-20 px-0">
        <div className="bg-abx-orange w-full mt-30">
          <div className="flex flex-col bg-abx-dark-grey text-white w-full sm:w-3/4 mx-10 sm:ml-10 relative top-20  p-12">
            <div className="justify-center font-semibold text-4xl lg:text-5xl h-full">
              <span className="text-white">Have a question?</span>
            </div>
            <div className="text-md h-full my-6 leading-relaxed">
              <div>
                Email us at&#58;{" "}
                <a
                  href="mailto:support@alchemybox.io"
                  className="link-white text-lg"
                >
                  support@alchemybox.io
                </a>
              </div>
            </div>
            <div>Or find us on social media</div>
            <div className="mt-5 flex items-center space-x-5">
              {/* Facebook */}
              <div>
                <a href="https://www.facebook.com/alchemybox/">
                  <svg
                    className="h-8 fill-current text-white hover:text-abx-pink"
                    viewBox="0 0 58 58"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M21.75 19.3333H14.5V29H21.75V58H33.8333V29H42.6348L43.5 19.3333H33.8333V15.3048C33.8333 12.9968 34.2973 12.0833 36.5279 12.0833H43.5V0H34.2973C25.607 0 21.75 3.82558 21.75 11.1529V19.3333Z" />
                  </svg>
                </a>
              </div>
              {/* Instagram */}
              <div>
                <a href="https://www.instagram.com/alchemybox.io/?igshid=uhhuy3d0fxha">
                  <svg
                    className="h-8 fill-current text-white hover:text-abx-pink"
                    viewBox="0 0 56 56"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M28 5.047C35.476 5.047 36.3627 5.075 39.3167 5.21033C46.9047 5.55567 50.449 9.156 50.7943 16.688C50.9297 19.6397 50.9553 20.5263 50.9553 28.0023C50.9553 35.4807 50.9273 36.365 50.7943 39.3167C50.4467 46.8417 46.9117 50.449 39.3167 50.7943C36.3627 50.9297 35.4807 50.9577 28 50.9577C20.524 50.9577 19.6373 50.9297 16.6857 50.7943C9.079 50.4467 5.55333 46.83 5.208 39.3143C5.07267 36.3627 5.04467 35.4783 5.04467 28C5.04467 20.524 5.075 19.6397 5.208 16.6857C5.55567 9.156 9.09067 5.55333 16.6857 5.208C19.6397 5.075 20.524 5.047 28 5.047ZM28 0C20.3957 0 19.4437 0.0326667 16.457 0.168C6.28833 0.634667 0.637 6.27667 0.170333 16.4547C0.0326667 19.4437 0 20.3957 0 28C0 35.6043 0.0326667 36.5587 0.168 39.5453C0.634667 49.714 6.27667 55.3653 16.4547 55.832C19.4437 55.9673 20.3957 56 28 56C35.6043 56 36.5587 55.9673 39.5453 55.832C49.7047 55.3653 55.37 49.7233 55.8297 39.5453C55.9673 36.5587 56 35.6043 56 28C56 20.3957 55.9673 19.4437 55.832 16.457C55.3747 6.29767 49.7257 0.637 39.5477 0.170333C36.5587 0.0326667 35.6043 0 28 0V0ZM28 13.622C20.0597 13.622 13.622 20.0597 13.622 28C13.622 35.9403 20.0597 42.3803 28 42.3803C35.9403 42.3803 42.378 35.9427 42.378 28C42.378 20.0597 35.9403 13.622 28 13.622ZM28 37.3333C22.8457 37.3333 18.6667 33.1567 18.6667 28C18.6667 22.8457 22.8457 18.6667 28 18.6667C33.1543 18.6667 37.3333 22.8457 37.3333 28C37.3333 33.1567 33.1543 37.3333 28 37.3333ZM42.9473 9.695C41.09 9.695 39.585 11.2 39.585 13.055C39.585 14.91 41.09 16.415 42.9473 16.415C44.8023 16.415 46.305 14.91 46.305 13.055C46.305 11.2 44.8023 9.695 42.9473 9.695Z" />
                  </svg>
                </a>
              </div>
              {/* Twitter */}
              <div>
                <a href="https://twitter.com/alchemybox_io">
                  <svg
                    className="h-8 fill-current text-white hover:text-abx-pink"
                    viewBox="0 0 63 63"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M63 11.9625C60.6821 12.9915 58.191 13.6845 55.5765 13.9969C58.2461 12.3982 60.2962 9.86512 61.2596 6.84637C58.7632 8.32687 55.9965 9.40312 53.0512 9.98324C50.6966 7.47112 47.334 5.90137 43.617 5.90137C35.2721 5.90137 29.1401 13.6871 31.0249 21.7695C20.286 21.2314 10.7625 16.0864 4.38637 8.26649C1.00013 14.0756 2.63025 21.675 8.38425 25.5232C6.2685 25.455 4.2735 24.8749 2.53312 23.9062C2.39137 29.8939 6.68325 35.4956 12.8993 36.7425C11.0801 37.236 9.08775 37.3515 7.06125 36.963C8.7045 42.0975 13.4768 45.8329 19.1362 45.9379C13.7025 50.1982 6.8565 52.1014 0 51.2929C5.71987 54.96 12.516 57.0994 19.8135 57.0994C43.8112 57.0994 57.3694 36.8317 56.5504 18.6536C59.0756 16.8292 61.2675 14.5534 63 11.9625Z" />
                  </svg>
                </a>
              </div>
              {/* LinkedIn */}
              <div>
                <a href="https://www.linkedin.com/company/alchemy-box">
                  <svg
                    className="h-8 fill-current text-white hover:text-abx-pink"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>
              </div>
              {/* Slack */}
              <div className="h-9">
                <a href="https://join.slack.com/t/alchemyboxworkspace/shared_invite/zt-qvv43zkg-m3HU3YbjV1w_jA~uyxzAMg">
                  <svg
                    className="h-20 fill-current text-white hover:text-abx-pink"
                    viewBox="0 0 58 58"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22.994 8.7c-1.817-6.055-4.223-8.7-8.636-8.7-1.6 0-3.464.347-5.658 1.006-6.056 1.817-8.7 4.223-8.7 8.636 0 1.6.347 3.463 1.006 5.658 1.816 6.056 4.222 8.7 8.635 8.7 1.6 0 3.463-.347 5.659-1.006 6.055-1.817 8.7-4.222 8.7-8.635 0-1.6-.348-3.464-1.006-5.659m-4.164 5.353l-1.554.519.537 1.611c.211.652-.133 1.362-.786 1.573-.735.208-1.373-.206-1.574-.786l-.537-1.612-3.204 1.074.537 1.612c.212.653-.134 1.363-.786 1.573-.73.208-1.371-.201-1.573-.787l-.538-1.611-1.554.518c-.725.21-1.371-.203-1.574-.787-.21-.652.135-1.362.787-1.573l1.554-.518-1.036-3.089-1.554.518c-.729.207-1.37-.2-1.573-.787-.211-.652.134-1.362.786-1.573l1.555-.518-.538-1.611c-.211-.653.135-1.363.787-1.574.652-.211 1.362.134 1.573.787l.538 1.611 3.203-1.074-.536-1.612c-.212-.652.134-1.362.786-1.573.653-.211 1.363.134 1.574.787l.537 1.612 1.554-.519c.652-.211 1.362.135 1.573.787.212.652-.134 1.362-.786 1.573l-1.555.518 1.036 3.089 1.555-.518c.652-.211 1.362.134 1.573.787.211.652-.135 1.362-.787 1.573m-5.747-4.117l-3.202 1.073 1.035 3.092 3.202-1.072-1.035-3.093z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
